import ApiService from "@/core/services/api.service";

export const SAVE_EMPLOYEE = (data) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/add-employee`, data)

      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_EMPLOYEE = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`employee/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
