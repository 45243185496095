<template>
  <Dialog :dialog="dialog">
    <template v-slot:title>{{ dialogTitle() }}</template>
    <template v-slot:body>
      <v-container v-if="dialog" fluid class="py-0">
        <v-form
          ref="orderForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate()"
        >
          <label for="date" class="btx-label required">Date</label>
          <DatePicker
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="date"
            class="required"
            placeholder="Date"
            :rules="[vrules.required(order.date, 'Date')]"
            :class="{ required: !order.date }"
            v-model="order.date"
          ></DatePicker>
          <label for="time" class="btx-label mt-4 required">Time</label>
          <TimePicker
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="time"
            placeholder="Time"
            :rules="[vrules.required(order.time, 'Time')]"
            :class="{ required: !order.time }"
            v-model="order.time"
          ></TimePicker>
          <label for="pos-bill" class="btx-label mt-4 required">POS Bill #</label>
          <TextInput
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="pos-bill"
            :rules="[vrules.required(order.bill_no, 'POS Bill #')]"
            :class="{ required: !order.bill_no }"
            placeholder="POS Bill #"
            v-model="order.bill_no"
          ></TextInput>
          <label for="amount" class="btx-label mt-4 required">Amount</label>
          <CurrencyInput
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="amount"
            placeholder="Amount"
            :rules="[vrules.required(order.amount, 'Amount')]"
            :class="{ required: !order.amount }"
            v-model="order.amount"
          ></CurrencyInput>
          <div class="mt-6">
            <v-btn
              v-on:click="updateVStatus('none')"
              depressed
              tile
              :class="{
                'blue-grey lighten-1 white--text': order.voucher == 'none',
                'blue-grey--text text--lighten-1': order.voucher != 'none',
              }"
            >
              None
            </v-btn>
            <v-btn
              v-on:click="updateVStatus('collect')"
              depressed
              tile
              class="mx-4"
              :class="{
                'cyan darken-2 white--text': order.voucher == 'collect',
                'cyan--text text--darken-2': order.voucher != 'collect',
              }"
            >
              Collect Voucher
            </v-btn>
            <v-btn
              v-on:click="updateVStatus('claim')"
              depressed
              tile
              :class="{
                'deep-orange darken-4 white--text': order.voucher == 'claim',
                'deep-orange--text text--darken-4': order.voucher != 'claim',
              }"
            >
              Redeem Voucher
            </v-btn>
          </div>
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading || !formValid"
        :loading="pageLoading"
        class="white--text mr-2"
        depressed
        color="blue darken-4"
        tile
        v-on:click="updateOrCreate()"
      >
        Save
      </v-btn>
      <v-btn :disabled="pageLoading" depressed tile v-on:click="closeDialog()"> Cancel </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { format } from "date-fns";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import CurrencyInput from "@/view/components/CurrencyInput";
import DatePicker from "@/view/components/DatePicker";
import TimePicker from "@/view/components/TimePicker";
import { UPDATE_OR_CREATE_ORDER } from "@/core/lib/order.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
  name: "create-order",
  title: "Create Order",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    customerId: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    dialog: {
      type: Boolean,
      default: false,
      formValid: true,
    },
  },
  watch: {
    value(param) {
      this.order = param;
    },
  },
  components: {
    Dialog,
    TextInput,
    CurrencyInput,
    DatePicker,
    TimePicker,
  },
  data() {
    return {
      formValid: true,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
      pageLoading: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", true);
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    dialogTitle() {
      if (this.order.id) {
        return "Update Order";
      }
      return "Add Order";
    },
    updateVStatus(value) {
      this.order.voucher = value;
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.orderForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;

        const order = await UPDATE_OR_CREATE_ORDER(_this.customerId, _this.order);

        if (_this.order.voucher == "collect") {
          if (!order.vcollected) {
            _this.$emit("collect:voucher", order.id, order.barcode);
          } else {
            _this.$store.commit(SET_ERROR, [
              { model: true, message: "Voucher Already Collected." },
            ]);
          }
        }

        if (_this.order.voucher == "claim") {
          if (!order.vredeemed) {
            _this.$emit("claim:voucher", order.id, order.barcode);
          } else {
            _this.$store.commit(SET_ERROR, [{ model: true, message: "Voucher Already Redeemed." }]);
          }
        }

        _this.$emit("save", true);
        _this.$emit("close", true);

        if (_this.order.id) {
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Order Updated Successfully." },
          ]);
        } else {
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Order Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.order = this.value;
  },
};
</script>
