import ApiService from "@/core/services/api.service";

export const GET_CUSTOMERS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`supplier/list`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_LEAVE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`employee/leave`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_ADVANCED = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`employee/advanced`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_CUSTOMER = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_UNLINKED_ORDERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/unlinked-orders`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_AVAILABLE_VOUCHERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/voucher/available`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_REDEEMED_VOUCHERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/voucher/redeemed`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_EXPIRED_VOUCHERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/voucher/expired`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_CUSTOMER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("customer", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_CUSTOMER = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`customer/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TEMPLATE_LIST = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.GET("whatsapp/template/all")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const GET_PROMOTION_LIST = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`temp-promotions`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_PROMOTION_EXPIRY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`temp-promotions-expiry`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ---------supplier-------------
export const CREATE_SUPPLIER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("supplier", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPLOAD_FILE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/upload-image`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// export const UPDATE_STOCK = (params) => {
//   return new Promise((resolve, reject) => {
//     ApiService.setHeader();
//     ApiService.post(`/import-stock-count`, params)
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const FIND_STOCK = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`stock-count`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPDATE_STOCK = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/import-stock-count`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const STOCKUPDATE = (formData) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/import-stock-count`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
};

export const CREATE_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-sale", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPDATE_SALES = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`create-sale/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CREATE_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-banking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const GET_CUST = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`customer`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};




