<template>
  <v-menu
    ref="datepicker"
    v-model="datepicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :id="id"
        :label="label"
        :loading="loading"
        :disabled="disabled"
        :clearable="clearable"
        outlined
        :rules="rules"
        :class="{ ...customClass, 'mt-3': !hideTopMargin }"

        readonly
        v-model="dateFormatted"
        :placeholder="btxPlaceholder"
        hide-details
        :prepend-inner-icon="hidePrefixIcon ? '' : 'mdi-calendar'"
        v-bind="attrs"
        v-on:click:clear="clearText()"
        v-on:blur="date = parseDate(dateFormatted)"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      :min="minDate"
      :max="maxDate"
      :rules="rules"
      @input="datepicker = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "date-picker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Date, String],
      default: null,
    },
    customClass: {
      type: [String,Object],
      default: "mt-2 pt-0",
    },
    hideTopMargin: {
      type: Boolean,
      default: false,
    },
    hidePrefixIcon: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: undefined, 
    },
    maxDate: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "Date",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      date: null,
      datepicker: false,
      dateFormatted: null,
      // minDate: null
    };
  },
  watch: {
    value() {
      this.date = this.value;
    },
    minDate() {
      this.date = null;
    },
    maxDate() {
      this.date = null;
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("input", this.date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    clearText() {
      this.$emit("input", null);
      this.$emit("click:clear", true);
    },
  },
  computed: {
    btxPlaceholder() {
      return this.placeholder + " [DD/MM/YYYY]";
    },
  },
  mounted() {
    this.date = this.value;
  },
};
</script>
