<template>
  <div class="v-input time-picker">
    <div class="v-input__control">
      <div class="v-input__slot">
        <vue-timepicker
          :id="id"
          :placeholder="btxPlaceholder"
          v-model="time"
          :value="time"
          class="btx-time-picker v-text-field__slot"
        ></vue-timepicker>
      </div>
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker";
import { toString } from "lodash";
export default {
  name: "time-picker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Date, String],
      default: null,
    },
    hidePrefixIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Date",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time: null,
      timepicker: false,
    };
  },
  watch: {
    value(param) {
      this.time = toString(param) || null;
    },
    time(param) {
      this.$emit("input", toString(param));
    },
  },
  computed: {
    btxPlaceholder() {
      return this.placeholder + " [HH:mm]";
    },
  },
  mounted() {
    this.time = toString(this.value);
  },
  components: {
    VueTimepicker,
  },
};
</script>
