<template>
  <Dialog dense :dialog="dialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      Redeem Vouchers <template v-if="orderCode">| Order #{{ orderCode }}</template>
    </template>
    <template v-slot:body>
      <v-container fluid class="pt-0">
        <v-row class="vouchers pt-6">
          <template v-if="vouchers.length">
            <v-col md="6" v-for="(row, index) in vouchers" class="p-3" :key="index">
              <div
                class="border-light-grey p-4 link"
                v-on:click="selectVoucher(row)"
                :class="{ 'voucher-selected': row.vcid == vcid }"
              >
                <v-layout>
                  <v-flex md4>
                    <ImageTemplate :src="row.image"></ImageTemplate>
                  </v-flex>
                  <v-flex md8 class="ml-4 my-auto">
                    <p class="m-0 v-name pb-2 black--text">{{ row.name }}</p>
                    <p class="m-0 v-expiry" v-if="row.expiry_date">
                      Expires: {{ formatDate(row.expiry_date) }}
                    </p>
                    <p class="m-0 v-code pt-6 text-center">{{ row.vcode }}</p>
                    <p class="m-0 pb-6 pt-2 v-coupon text-center">COUPON</p>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md12>
                    <span class="float-left">
                      <v-chip class="ma-2" color="green" text-color="white"> Collected </v-chip>
                    </span>
                    <span class="float-right">
                      <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip class="ma-2" v-bind="attrs" v-on="on"> Terms of use </v-chip>
                        </template>

                        <v-card flat max-width="400px" rounded="0">
                          <v-card-text flat rounded="0">
                            <label class="btx-label"> TERMS OF USE</label>
                            <p class="light-border-top py-2">{{ row.description }}</p>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </span>
                  </v-flex>
                </v-layout>
              </div>
            </v-col>
          </template>
          <v-col v-else md="12" class="text-center">
            <p class="m-0 text-center black--text text-h5">
              <img
                width="50"
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image mr-4"
              />
              Uhh... There are no voucher collected at the moment.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading || !vcid"
        :loading="pageLoading"
        class="white--text mr-2"
        depressed
        color="blue darken-4"
        tile
        v-on:click="saveVoucher()"
      >
        Redeem
      </v-btn>
      <v-btn :disabled="pageLoading" depressed tile v-on:click="closeDialog()"> Cancel </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";
import ImageTemplate from "@/view/components/Image";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { REDEEM_VOUCHER } from "@/core/lib/common.lib";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: 0,
    },
    orderCode: {
      type: String,
      default: null,
    },
    vouchers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      vcid: null,
    };
  },
  methods: {
    closeDialog() {
      this.vcid = null;
      this.$emit("close", true);
    },
    selectVoucher({ vcid }) {
      this.vcid = vcid;
    },
    async saveVoucher() {
      if (this.vcid) {
        try {
          await REDEEM_VOUCHER(this.vcid, this.orderId);
          this.$emit("redeemed", true);
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Voucher Redeemed Successfully" },
          ]);
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        }
      } else {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Please Select Voucher." }]);
      }
    },
  },
  components: {
    Dialog,
    ImageTemplate,
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
