<template>
  <v-sheet class="employee" style="height: calc(100vh - 340px)">
    <v-flex v-if="primary_checkbox" class="my-auto py-0 d-flex justify-content-between">
      <p for="segment_name" class="custom-form-label" style="font-weight: 600">
        Contact Person (2)
      </p>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="white--text mr-2"
        depressed
        color="blue darken-4"
        tile
        v-on:click="addContactPerson()"
      >
        ADD CONTACT PERSON
      </v-btn>
    </v-flex>
    <!-- {{ get_salaryDataOf_employee?.employee_salary_details }} -->
    <v-simple-table fixed-header class="bt-table table_height_sales">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="heading in salestableHeading"
              :key="heading.title"
              style="background-color: #f5f5f5"
            >
              <div v-if="heading.status">{{ heading.title }}</div>
            </th>
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="employees.length">
            <!-- v-for="(row, index) in salesSummaryDate" :key="index" -->
            <tr>
              <td
                v-for="(th, index) in salestableHeading"
                :key="'key_' + index"
                class="cursor-pointer"
              >
                <div v-if="getColValue(th.title) === 'action' && th.status">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        class="mx-2"
                        color="blue darken-4"
                        v-bind="attrs"
                        v-on="on"
                        link
                        @click="downloadSalaraData"
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                </div>
                <!-- <div v-else-if="getColValue(th.title) === 'employee_id' && th.status">
                  <v-flex class="d-flex align-center px-0">
                    <v-checkbox v-if="primary_checkbox" color="blue darken-4"></v-checkbox>
                    <v-chip small class="text-white" color="#0D47A1" label>
                      {{ row[getColValue(th.title)]?.value }}
                    </v-chip>
                  </v-flex>
                </div> -->
                <!-- <div v-else-if="getColValue(th.title) === 'email' && th.status">
                  {{ row[getColValue(th.title)]?.value }}
                </div> -->
                <div v-else-if="getColValue(th.title) === 'month' && th.status">
                  <v-chip color="#0D47A1" outlined> {{ row[getColValue(th.title)]?.value }}</v-chip>
                </div>

                <div v-else-if="getColValue(th.title) === 'over_time' && th.status">
                  <v-chip color="#0D47A1" outlined>{{ row[getColValue(th.title)]?.value }}</v-chip>
                </div>
                <div v-else-if="getColValue(th.title) === 'deducation_rm' && th.status">
                  {{ row[getColValue(th.title)]?.value }}
                </div>
                <div v-else-if="getColValue(th.title) === 'incentive_rm' && th.status">
                  <template v-if="row[getColValue(th.title)]?.value">
                    <p class="my-auto py-0">{{ row[getColValue(th.title)]?.value }}</p>
                  </template>
                  <template v-else>
                    <p style="font-size: 16px; font-weight: 600">no last name</p>
                  </template>
                </div>
                <div v-else-if="getColValue(th.title) === 'final_salary_rm' && th.status">
                  {{ row[getColValue(th.title)]?.value }}
                </div>

                <div v-else-if="getColValue(th.title) === 'penalty' && th.status">
                  {{ row[getColValue(th.title)]?.value }}
                </div>
                <div v-else-if="getColValue(th.title) === 'probation_period' && th.status">
                  <v-chip color="#0D47A1" outlined>{{ row[getColValue(th.title)]?.value }}</v-chip>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no employee at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="idr">
            <td v-for="idk in 9" :key="idk">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="employees.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>

    <Dialog :dialog="dialog">
      <template v-slot:title>
        {{ dialogTitle }}
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <v-form
            ref="voucherForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate()"
          >
            <v-row>
              <v-col md="12">
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="voucher-name" class="btx-label mt-2 required">Primary</label>
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPersons.primary"
                      :rules="[vrules.required(contactPersons.primary, 'Primary')]"
                      :class="{ required: !contactPersons.primary }"
                      id="primary"
                      placeholder="Primary"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="last-name" class="btx-label mt-2 required">First Name</label>
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPersons.first_name"
                      :rules="[vrules.required(contactPersons.first_name, 'First Name')]"
                      :class="{ required: !contactPersons.first_name }"
                      id="first-name"
                      placeholder="First Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="last-name" class="btx-label mt-2 required">Last Name</label>
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPersons.last_name"
                      :rules="[vrules.required(contactPersons.last_name, 'Last Name')]"
                      :class="{ required: !contactPersons.last_name }"
                      id="last-name"
                      placeholder="Last Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="minimum-order-amount" class="btx-label mt-2 required"
                      >Display Name</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPersons.display_name"
                      :rules="[vrules.required(contactPersons.display_name, 'Display Name')]"
                      :class="{ required: !contactPersons.display_name }"
                      id="display-name"
                      placeholder="Display Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="minimum-order-amount" class="btx-label mt-2 required"
                      >Mobile Number</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <PhoneTemplate
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPersons.mobile_number"
                      :rules="[vrules.required(contactPersons.mobile_number, 'Mobile No')]"
                      :class="{ required: !contactPersons.mobile_number }"
                      id="mobile-no"
                      placeholder="Mobile no."
                    ></PhoneTemplate>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="expiry-date" class="btx-label mt-2">Did</label>
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPersons.did"
                      id="did"
                      placeholder="Did"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="expiry-date" class="btx-label mt-2 required">Email</label>
                  </v-flex>
                  <v-flex md8>
                    <EmailInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPersons.email"
                      :rules="[vrules.required(contactPersons.email, 'Email')]"
                      :class="{ required: !contactPersons.email }"
                      id="email"
                      placeholder="Email"
                    ></EmailInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="expiry-date" class="btx-label mt-2">Designation</label>
                  </v-flex>
                  <v-flex md8>
                    <SelectInput
                      id="designation"
                      hide-details
                      :loading="loading"
                      :disabled="disabled"
                      item-text="text"
                      :items="['Manager', 'Director', 'Seo']"
                      v-model="contactPersons.designation"
                      placeholder="Designation"
                    ></SelectInput>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate()"
        >
          Save
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile @click="dialog = false"> Cancel </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { GET_CUSTOMERS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import PhoneTemplate from "@/view/components/Phone";
// import {
//   SET_SEARCH_TITLE,
//   SET_SEARCH_MODEL,
//   SET_SEARCH_BAR,
//   BEFORE_DESTROY,
// } from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";

export default {
  name: "sales-update",
  title: "Listing Sales",
  props: {
    primary_checkbox: {
      type: Boolean,
      default: false,
    },
    salaryDetalsList: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      formValid: true,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      dialogTitle: "ADD CONTACT PERSON",
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      employees: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      salestableHeading: [
        { title: "ACTION", status: true },
        { title: "MONTH", status: true },
        { title: "OVER TIME", status: true },
        { title: "INCENTIVE (RM)", status: true },
        { title: "PENALTY", status: true },
        { title: "DEDUCATION (RM)", status: true },
        { title: "FINAL SALARY (RM)", status: true },
      ],
      salesSummaryDate: [
        {
          action: { value: "", key: "ACTION" },
          employee_id: { value: "RKTJ4565", key: "EMPLOYEE ID" },
          email: { value: "snha@gmail.com", key: "EMAIL" },
          month: { value: "JUNE 2023", key: "MONTH" },
          over_time: { value: "3 HOURS", key: "OVER TIME" },
          phone_number: { value: "+65 49857345", key: "PHONE NUMBER" },
          penalty: { value: "200.00", key: "PENALTY" },
          probation_period: { value: "2 MONTH", key: "PROBATION PERIOD" },
          deducation_rm: { value: "500.00", key: "DEDUCATION" },
          incentive_rm: { value: "1500.00", key: "INCENTIVE" },
          final_salary_rm: { value: "34000.00", key: "FINAL SALARY" },
        },
        {
          action: { value: "", key: "ACTION" },
          employee_id: { value: "RKTJ4565", key: "EMPLOYEE ID" },
          email: { value: "snha@gmail.com", key: "EMAIL" },
          over_time: { value: "3 HOURS", key: "OVER TIME" },
          month: { value: "JULY 2023", key: "MONTH" },
          penalty: { value: "300.00", key: "PENALTY" },
          probation_period: { value: "2 MONTH", key: "PROBATION PERIOD" },
          deducation_rm: { value: "500.00", key: "DEDUCATION" },
          incentive_rm: { value: "1500.00", key: "INCENTIVE" },
          final_salary_rm: { value: "34000.00", key: "FINAL SALARY" },
        },
      ],
      get_salaryDataOf_employee: [],
      dragStartIndex: null,
      dragOverIndex: null,
      contactPersons: {
        primary: null,
        first_name: null,
        last_name: null,
        display_name: null,
        mobile_number: null,
        did: null,
        email: null,
        designation: null,
      },
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    currentPage() {
      this.getSummary();
    },
    listingSearch() {
      this.getSummary();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getSummary();
      }
    },
  },
  methods: {
    downloadSalaraData() {
      const summaryContent = this.generateCSV();
      const blobContent = new Blob([summaryContent], { type: "text/csv" });
      const downLoadlink = document.createElement("a");
      downLoadlink.href = window.URL.createObjectURL(blobContent);
      downLoadlink.download = "restaurant.csv";
      downLoadlink.click();
    },
    generateCSV() {
      const tableHeaders = [
        "EMPLOYEE ID",
        "EMAIL",
        "OVER TIME",
        "PHONE NUMBER",
        "ROLE",
        "PROBATION PERIOD",
        "DEDUCATION (RM)",
        "INCENTIVE (RM)",
        "FINAL SALARY (RM)",
      ];
      const rows = this.salesSummaryDate.map((item) => [
        item.employee_id.value,
        item.email.value,
        item.over_time.value,
        item.deducation_rm.value,
        item.incentive_rm.value,
        item.final_salary_rm.value,
        item.phone_number.value,
        item.role.value,
        item.probation_period.value,
      ]);
      console.log(rows, "rows");
      const csvRows = [tableHeaders.join(","), ...rows.map((row) => row.join(","))];
      return csvRows.join("\n");
    },
    addContactPerson() {
      this.dialog = true;
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").replaceAll("(", "").replaceAll(")", "").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.salestableHeading[this.dragStartIndex];
      this.salestableHeading.splice(this.dragStartIndex, 1);
      this.salestableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getSummary();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "sales-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "sales-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `employee/${id}`;
      this.deleteDialog = true;
    },
    async getSummary() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_CUSTOMERS(form);
        this.employees = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          // this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getSummary();
    // await this.getTemplateListing();
    // this.$store.dispatch(SET_SEARCH_BAR, true);
    // this.$store.dispatch(SET_SEARCH_TITLE, "CREATE SUPPLIER");
    this.get_salaryDataOf_employee = this.salaryDetalsList;
  },
 
  components: {
    Dialog,
    TextInput,
    EmailInput,
    SelectInput,
    PhoneTemplate,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.advace_list_menu {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: space-between;
}

.table_height_sales .v-data-table__wrapper {
  height: calc(100vh - 340px);
}

::-webkit-scrollbar {
  height: 10px;
}

tr:nth-child(odd) {
  background-color: rgb(199 201 53 / 15%);
}
</style>
