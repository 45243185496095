import ApiService from "@/core/services/api.service";

export const GET_ORDERS = (customer, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`customer/${customer}/order`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const UPDATE_OR_CREATE_ORDER = (customer, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`customer/${customer}/order`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
